.btn {
  box-shadow: 0 20px 25px rgba(170, 170, 170, 0.2);

  padding: 17px 44px 15px 44px;

  transition: box-shadow 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;

  &:hover {
    box-shadow: 0 20px 25px rgba(170, 170, 170, 0.4);
  }
}