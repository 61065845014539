.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
  padding: 85px 70px;
}

.bodyDetails {
  padding: 22px 30px 45px 58px;
}

.detailsForm {
  padding: 42px 10px 10px 32px;
}
