.analytic-widgets {
  .roi-wrapper {
    .card-header {
      @apply px-29px pt-20px pb-0;

      .card-title {
        @apply font-bold text-base text-gray-400 uppercase;
      }
    }

    .divider {
      display: none;
    }

    .card-content {
      & > div {
        &:first-child {
          @apply bg-white border-3 border-gray-260 h-70px w-full px-30px ml-13px mt-7px justify-between;
          width: calc(100% - 21px);
          border-radius: 35px;
          box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);

          & > div {
            @apply m-0 h-full pt-13px px-10px;

            .label {
              @apply font-medium text-sm text-gray-150 m-0;
            }

            input {
              @apply font-medium text-base text-gray-400 border-0 p-0 bg-transparent h-auto;
            }

            .button {
              @apply bg-transparent border-0 font-medium text-base text-gray-400 p-0 h-auto;
            }

            &:not(:last-child) {
              @apply border-r-3 border-gray-260;
            }
          }
        }
      }
    }

    .highcharts-legend {
      text {
        @apply font-medium text-sm text-gray-150;
      }
    }

    .highlights-container {
      @apply p-9px;

      .highlight-container {
        @apply mt-0 ml-0 mr-20px;

        .title {
          @apply font-medium text-base text-gray-150 border-gray-290 border-b-3 pb-1px mb-4px;
        }

        .text-container {
          @apply font-medium text-2xl text-gray-400;
        }
      }
    }
  }

  .general-wrapper {
    .defi-card {
      .toolbar-chart {
        @apply px-29px pt-22px pb-0 h-auto;

        .chart-title {
          @apply font-bold text-base text-gray-400 uppercase;
        }
      }

      .block-chart {
        @apply pt-0;
      }

      .itb-chart-tabs {
        & > div{
          @apply font-medium text-sm text-blue-400 bg-transparent border-0;
        }
      }
    }

    .divider {
      display: none;
    }
  }
}