.wrapper {
  height: 68px;
  border-radius: 34px;
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.8);

  padding-left: 34px;
}

.menuLinkWrapper {
  padding: 0 25px;

  &:last-child {
    margin-right: 11px;
  }
}

.menuSubLinkWrapper {
  border-radius: 18px;

  padding: 8px 17px;

  margin-bottom: 8px;
}

.walletBtn {
  border-radius: 30px;
  padding: 0 25px;
}

.walletBtnAnimated {
  animation: pulse-border-red 1s 3 cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes pulse-border-red {
  0% {
    box-shadow: 0 0 10px 5px rgba(230, 0, 0, 0.9);
  }
  90%, 100% {
    box-shadow: 0 0 10px 25px rgba(230, 0, 0, 0.1);
  }
}

.walletText {
  padding-right: 15px;
}

.menuDropdown {
  width: 187px;
  top: 33px;
  left: -84px;
  border-radius: 22px;

  backdrop-filter: blur(50px);
  //background-color: rgba(255, 255, 255, 0.8);
  background-color: #F1F1F1;

  &Active {
    padding-top: 34px;
    padding-bottom: 24px;
  }
}