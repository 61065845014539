.circleWrapper {
  width: 52px;
  height: 52px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 20px;
  height: 20px;

  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
}

.circleRing {
  width: 24px;
  height: 24px;
  box-sizing: content-box;
}

.ringGreenAnimation {
  animation: pulse-ring-green 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.ringRedAnimation {
  animation: pulse-ring-red 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-dot {
  0% {
    transform: scale(.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse-ring-green {
  0% {
    width: 24px;
    height: 24px;
    border-color: rgba(0, 123, 50, 1);
  }
  90%, 100% {
    width: 38px;
    height: 38px;
    border-color: rgba(0, 123, 50, 0.05);
  }
}

@keyframes pulse-ring-red {
  0% {
    width: 24px;
    height: 24px;
    border-color: rgba(230, 0, 0, 1);
  }
  90%, 100% {
    width: 38px;
    height: 38px;
    border-color: rgba(230, 0, 0, 0.05);
  }
}

.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
  padding: 45px 30px 45px 86px;
}

.bodyDetails {
  padding: 22px 30px 45px 58px;
}

.infoRow {
  width: 87.5%;
}

.manageRow {
  padding-left: 89px;
}

.detailsForm {
  padding: 42px 10px 10px 32px;
}

.detailDescription {
  min-height: 118px;
}