.tableWrapper {
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    .table {
      width: 100%;
      border-spacing: 0;
    }

    .thead {
      min-width: fit-content;
      .th {
        padding: 12px 19px;
        font-weight: 500;
      }
    }

    .tbody {
      .tr {
        //overflow: hidden;

        //&:first-child {
        //  .td {
        //    &:first-child {
        //      border-top-left-radius: 12px;
        //    }
        //
        //    &:last-child {
        //      border-top-right-radius: 12px;
        //    }
        //  }
        //}
        //
        //&:last-child {
        //  .td {
        //    &:first-child {
        //      border-bottom-left-radius: 12px;
        //    }
        //
        //    &:last-child {
        //      border-bottom-right-radius: 12px;
        //    }
        //  }
        //}
      }

      .td {
        padding: 19px 15px 18px 19px;
      }
    }

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      font-size: 14px;
      font-weight: 400;

      margin: 0;
      padding: 0.5rem;
    }

    /* The secret sauce */
    /* Each cell should grow equally */
    //width: 1%;
    /* But "collapsed" cells should be as small as possible */
    &.collapse {
      width: 0.0000000001%;
    }

    &.selectable {
      .thead {
        .tr {
          padding: 0 35px;
        }
      }

      .tbody {
        .tr {
          padding: 0 35px;

          &:after {
            content: '';

            display: block;
            position: absolute;
            top: -2px;
            left: 8px;

            width: calc(100% - 16px);
            height: calc(100% + 2px);

            background: white;

            z-index: -1;

            border-radius: 50px;
            border: 2px solid #CBD8DA;
            box-shadow: 0 10px 14px rgba(0, 0, 0, 0.07);

            opacity: 0;

            transition: opacity 0.15s ease-in-out;
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .headerTr {

  }

  .headerTh {
    @apply font-medium text-sm text-opacity-60 text-gray-320;

    border-bottom: 2px solid #CBD8DA;

    position: relative;

    &:after {
      content: '';

      position: absolute;
      top: 100%;
      left: 0;

      width: 100%;
      height: 9px;
      background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.1;
    }

    &.left {
      @apply text-left justify-start;
    }

    &.right {
      @apply text-right justify-end;
    }

    &.center {
      @apply text-center justify-center;
    }
  }

  .bodyTd {
    &.left {
      @apply text-left justify-start;
    }

    &.right {
      @apply text-right justify-end;
    }

    &.center {
      @apply text-center justify-center;
    }

    &.first {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 5px;
        height: calc(100% + 2px);
      }

      &.inner {
        text-indent: 15px;

        &.lastInnerRow {
          overflow: hidden;
        }
      }
    }

    border-bottom: 2px solid #CBD8DA;
  }

  .tbody {
    position: relative;
  }

  .bodyTr {

  }
}