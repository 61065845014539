.wrapper {
  width: 440px;
  height: 122px;
  border-radius: 60px;

  box-shadow: 0 25px 30px rgba(64, 67, 63, 0.3);
  backdrop-filter: blur(50px);

  padding: 12px 10px 10px 10px;

  transition: background-color 0.25s ease-in-out;
}

.notificationIcon {
  width: 19%;
}

.body {
  padding-bottom: 3px;
}

.delimiter {
  width: 5px;
  margin: 0 30px 0 16px;
}

.title {
  line-height: 17px;
}

.link {
  margin-top: 3px;
}

.progress {
  height: 5px;

  margin-top: 13px;
}

.closeWrapper {
  margin-left: 26px;
}

.progressLineAnimation {
  width: 100%;
  transition-property: width;
  transition-timing-function: linear;
}