.formWrapper {
  height: 100px;

  transition: width 0.25s ease-in-out;
}

.formWrapperShort {
  width: 304px;
}

.formWrapperShortReadonly {
  width: 280px;
}

.formWrapperSmall {
  width: 240px;
}

.formWrapperLong {
  width: 401px;
}

.form {
  width: 100%;
  height: 100px;
  max-height: 100px;
  border-radius: 50px;

  transition: max-height 0.25s ease-in-out, height 0.25s ease-in-out;
}

.disabledForm {
  border-color: #c1c4c8;
  cursor: not-allowed;
}

.formOpened {
  width: 401px;
  height: 464px;
  max-height: 464px;
}

.formShadowed {
  box-shadow: inset 0 3px 15px rgba(0, 0, 0, 0.16);
}

.backArrowWrapper {
  left: 44px;
}

.arrowWrapper {
  width: 60px;
  height: 60px;
}

.arrowsWrapper {
  width: 30px;
  height: 67px;
}

.arrowWrapperDefault {
  right: 13px;
  box-shadow: 0 20px 25px rgba(170, 170, 170, 0.2);
}

.arrowWrapperExpanded {
  right: 14px;
}

.placeholderRow {
  height: 94px;
}

.placeholder {
  font-size: 28px;

  transition: font-size 0.25s ease-in-out, padding 0.25s ease-in-out;
}

.placeholderOpen {
  font-size: 26px;
}

.searchForm {
  height: 160px;
}

.tokenNameWrapper {
  max-width: calc(100% - 32px);
  width: 100%;
}

.searchFormShort {
  height: 130px;
}

.formBody {
  @apply absolute w-full top-0 right-0;
  transition: opacity 0.25s ease-in-out;
}

.formBodyShow {
  opacity: 1;
  pointer-events: all;
}

.formBodyHide {
  opacity: 0;
  pointer-events: none;
}

.warningMsg {
  border-radius: 20px;
}

.onOffbutton {
  width: 47px;
  height: 28px;
  border-radius: 14px;
}

.amountInput {
  max-width: 140px;
}
