.wrapper {
  width: 1642px;
}

.searchWrapper {
  width: 294px;
}

.tokenDropdownWrapper {
  width: 294px;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);
}