.btn {
  padding: 11px 17px 9px 17px;

  transition: box-shadow 0.25s linear;

  width: 400px;

  &:hover {
    box-shadow: 0 20px 25px rgba(170, 170, 170, 0.2);
  }
}