.logoWrapper {
  top: 46px;
  left: 61px;

  width: 280px;
  height: 42px;

  &>div {
    overflow: visible !important;
  }

  img {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
  }
}

.menuWrapper {
  top: 33px;
  right: 47px;
}

.notificationWrapper {
  bottom: 73px;
}

.mapWrapper {
  bottom: 68px;
  right: 59px;
}

.contentWrapper {
  padding-top: 100px;
}

.loader {
  width: 720px;
  height: 64px;

  margin-top: 162px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #E4F1FF;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3), inset 0px 0px 8px #FFFFFF;

  border-radius: 32px;
}

.loaderForm {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), #2D41BC;

  box-shadow: 0px 0px 8px #FFFFFF, inset 0px 0px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 4px rgba(0, 0, 0, 0.25);

  border-radius: 24px;
}

.loaderActiveProgress {
  background: linear-gradient(180deg, #FF9A48 0%, #F25625 100%);

  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.35), inset 0px -3px 3px #F18749, inset 0px -7px 20px rgba(255, 46, 0, 0.6), inset 0px 4px 4px rgba(255, 213, 104, 0.6), inset 0px 0px 6px 3px rgba(184, 55, 0, 0.3);

  border-radius: 22px;

  top: 2px;
  left: 2px;

  height: calc(100% - 4px);

  min-width: 44px;

  transition: width 0.2s linear;
}