.btn {
  box-shadow: 0 20px 25px rgba(170, 170, 170, 0.2);

  padding: 16px 44px;
}

.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
  padding: 85px 70px;
}

.bodyDetails {
  padding: 22px 30px 45px 58px;
}

.detailsForm {
  padding: 42px 10px 10px 32px;
}