.form {
  padding: 61px 63px 33px 55px;
}

.itemWrapper {
  max-width: 400px;
}

.changableBlock {
  height: 430px;
}

.body {
  @apply transition-opacity h-full w-full absolute top-0 left-0;
}
