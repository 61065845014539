.balanceRow {
  top: 95%;
}

.maxBtnWrapper {
  bottom: -34px;
  right: 26px;
}

.maxBtn {
  width: 50px;
  height: 28px;
  border-radius: 5px;
  box-shadow: 0 12px 15px rgba(255, 196, 0, 0.27);
}

.tokenName {
  max-width: 230px;
}