.assetForm {
  padding: 67px 20px 39px 66px;

  max-height: 290px;
}

.receiveForm {
  padding: 42px 40px 20px 65px;
}

.title {
  @apply font-medium text-xl text-gray-400 text-opacity-60 uppercase tracking-normal;
  min-width: 187px;
  max-width: 50%;
}

.valueWrapper {
  max-width: 50%;
}

.value {
  @apply font-medium text-xl text-gray-400 uppercase tracking-normal w-full;
}

.leftColumn {
  width: 61%;
}

.rightColumn {
  width: 39%;
}

.infoRow {
  @apply flex items-center;
  margin-bottom: 11px;
}

.valWrapper {
  max-width: 100%;

  span {
    width: 100%;
  }
}