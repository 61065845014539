.value {
  @apply font-medium text-35px text-gray-400 leading-8 tracking-normal;
  width: 99%;
}

.plusWrapper {
  width: 76px;
  min-width: 76px;
  height: 72px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.valueColumnWrapper {
  width: calc(50% - 55px);
}