.wrapper {
  filter: drop-shadow(0px 25px 30px rgba(0, 57, 149, 0.29));

  width: 310px;
  height: 56px;

  top: calc(100% + 10px);

  border-bottom-left-radius: 52px;
  border-bottom-right-radius: 52px;

  transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
}

.wrapperOpen {
  width: 900px;
  height: 76px;
}

.column {
  max-width: 20%;

  transition: opacity 0.5s ease-in-out;
}

.delimiter {
  height: 50px;
}

.arrowWrapper {
  width: 24px;
  height: 16px;
}

.show {
  opacity: 1;
  pointer-events: auto;
}

.hide {
  opacity: 0;
  pointer-events: none;
}