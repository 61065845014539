.form {
  padding: 85px 29px 0 60px;
}

.searchWrapper {
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05), inset 0 3px 15px rgba(0, 0, 0, 0.16);
  width: 400px;
}

.listWrapper {
  height: calc(100% - 86px);
  overflow: hidden;

  position: relative;

  mask-image: linear-gradient(rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 0.3));

  [class~="ps__rail-y"]{
    transform: scaleY(0.75) translateX(-1px);
    transform-origin: top center;
  }
}